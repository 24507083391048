import React from 'react';
import { Spinner } from '../../components/Spinner';
import { withLayout } from '../../containers/Layout';
import { useApi } from '../../hooks/useApi';
import { Family } from '@felicetteapp/types';
const PageFamiliesBase = () => {
  const { response, fetching, error } = useApi<{
    families: Array<Family>;
  }>('admin/families', { method: 'get' });
  console.log({ response, fetching, error });
  return fetching ? (
    <Spinner />
  ) : error ? (
    <div className="px-2 py-2 text-center bg-red-500">ops!</div>
  ) : (
    <ul>
      {response.data.families.map((family) => (
        <li className="py-1">
          <small>{family.id}</small> : {family.name}
        </li>
      ))}
    </ul>
  );
};
const PageFamilies = React.memo(PageFamiliesBase);
export default () => withLayout({ title: 'Families' }, <PageFamilies />);
